@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    border: none;
    outline: none;
    box-sizing: border-box;
}

*::-webkit-scrollbar {
    width: 10px;
    // display: none;
    /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: #fafafa;
    // display: none;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(188, 188, 188, 0.74);
    /* color of the scroll thumb */

    // border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 1px solid #fafafa;
    /* creates padding around scroll thumb */
    // display: none;
}

.topnav {
    // position: absolute;
    align-self: flex-start;

    left: 0px;
    top: 0px;
    font-size: 0px;
    width: 100%;
    height: 9vh;
    background-color: #000;
}

// .beyond_logo {
//     display: inline-block;

//     // padding: 10px;
// }

.log_out {
    float: right;
    // display: inline-flex;
    // align-items: flex-end; 
    line-height: 50%;

    height: 2vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-right: 2.5vw;

    cursor: pointer;


}

.logo_svg {
    height: 5vh;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    margin-left: 2.5vw;
}

canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.lista_reportes_ {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #fff;
    // font-weight: 700;
    font-size: 1.2vw;
    // margin: 0px 8px;
    cursor: pointer;
}

.img_responsive {
    display: block;
    height: 50px;
    width: 100%;
    padding: 5px;
    margin-top: 3px;
    margin-bottom: 4px;
    // max-width: 100%;
    // height: auto;
}

// #root_root {
//     margin: 0px;
//     padding: 0px;
// }
#display_container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    // background-color: #ebebeb;
}

#marca_report {
    // max-width: 90%;
    text-transform: capitalize;
    font-size: 3vw;
    width: 80%;
    display: inline;
    // color: red;
    float: left;
}

.li_reporte {
    margin: 10px 0px;
    padding: 5px 11px;
}

.li_reporte_current {
    background-color: rgb(62 66 75);
    border-left-style: solid;
    border-left-width: 4px;
}

.subtitulo_report {
    margin: 2px 8px;
    padding: 6px;
    // text-decoration: underline;
    font-size: 1.2rem;
    color: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.wrapper_img_report {
    background-color: white;
    margin-bottom: 10px;
}




@media screen and (min-width: 1500px) {
    #marca_report {
        font-size: 45px;
    }

    .reportsDiv__conteiner-brand {
        // width: 256px;
    }

}

// @media screen and (max-width: 800px) {
//     .logo_svg {
//         height: 16px;
//         margin-top: 1.5vw;
//         margin-bottom: 1.5vw;
//         margin-left: 2.5vw;
//     }

// }
.container_footer_brand_rabioso {
    position: absolute;
    right: 0%;
    width: 100%;
}

.container_footer_brand {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;

}

#root {

    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 0;
    left: 0px;
    top: 0px;
    overflow: hidden;
}

.logger {
    width: 100vh;
    height: 75vh;
    background: white;
    box-shadow: 0 12px 56px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 10px;
    display: flex;
    align-items: center;

    .image-container{
        background-color: black;
        width: 40vh;
        height: 100%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .forgot_pass {
        padding-top: 70px;
        color: #E95A24;
        cursor: pointer;
        font-size: 0.8rem;
        background-color: transparent;
        text-align: start;
    }

    img {
        height: 80px;
        text-align: left;
    }

    .form-container{
        height: 100%;
        width: 100%;
        padding: 7% 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1{
            padding-bottom: 27px;
            color: #E95A24;
            font-size: 24px;
            font-weight: 700;
        }
        form {

            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: flex-end;

                input {
                    width: 100%;
                    height: 2.5rem;
                    border-radius: 5px;
                    border-bottom: 1px solid rgba(26, 23, 23, 0.38);
                    padding: 5px;

                }
    
                label {
                    position: absolute;
                    font-size: 0.8rem;
                    top: -0.5rem;
                }

                .password-toggle{
                    background: transparent;
                    z-index: 99999;
                    margin-top: -60px;
                    margin-right: 10px;
                    font-size: 18px;
                }
        
    
            .submit {
                padding: 5px;
                height: 40px;
                font-weight: 700;
                color: #000;
                background: #ffb60b;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                border: 0px !important;
                /* Note: backdrop-filter has minimal browser support */
                cursor: pointer;
                border-radius: 10px;
                transition: 1.5s;
    
                &:hover {
                    box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);
                }
            }
        }
    }

}

.reportsDiv {
    width: 86vw;
    height: 84vh;
    // background: rgba(204, 204, 204, 0.1);
    // box-shadow: 0 12px 56px 0 rgba(31, 38, 135, 0.37);
    // backdrop-filter: blur(15px);
    // -webkit-backdrop-filter: blur(15px);
    // border-radius: 10px;
    // border: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    // flex-flow: row wrap;

    &__conteiner {
        width: 100%;
        // height: 80%;
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        // flex-wrap: wrap;
        justify-content: space-around;
        // // align-content: space-around;
        overflow-x: auto;
        // align-content: stretch;
        // align-items: stretch;
        // align-items: flex-start;
        gap: 3vw;

        &-brand {
            cursor: pointer;
            height: 10vw;
            // flex-grow: 1;
            // flex-shrink: 1;

            // width: 10rem;
            // width: 23%;
            border: 1px solid rgba(255, 255, 255, 0.25);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.5rem;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.4);
            box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.108);
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
            transition: 1s;

            &:hover {
                transform: scale(1.05);
            }

            p {
                text-transform: capitalize;
                font-size: 1.5rem;
                font-weight: 800;
                text-align: center;
            }

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}

.centered_ {}

.display {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    background-image: url(../public/bg.png);
    background-size: cover;
    // display: inline-flex;
    // flex-direction: column;
    // justify-content: center;
    align-items: center;
}

.buttonBeyond {
    // margin-top: 5vh;
    // margin-bottom: 10vh;

    padding: 5px;
    width: 150px;
    height: 40px;
    color: #fafafa;
    background: #ffb60b;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border: 0.732572px solid rgba(188, 188, 188, 0.74);
    box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
    backdrop-filter: blur(21.6109px);
    /* Note: backdrop-filter has minimal browser support */
    cursor: pointer;
    border-radius: 36.6286px;
    transition: 1.5s;

    &:hover {
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);
    }
}

.btn_beyond_brands_flotante {
    position: absolute;
    right: 10vw;
    bottom: 10vh;
}

.logout {
    padding: 5px;
    width: 150px;
    height: 40px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fafafa;
    background: #ffb60b;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border: 0.732572px solid rgba(188, 188, 188, 0.74);
    box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 0.18);
    backdrop-filter: blur(21.6109px);
    /* Note: backdrop-filter has minimal browser support */
    cursor: pointer;
    border-radius: 36.6286px;
    transition: 1.5s;

    &:hover {
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);
    }
}

@media screen and (min-width: 900px) {
    .singleBrand {
        max-width: 900px;
    }

    .singleBrand h2 {
        width: 100%;
    }

}

@media screen and (max-width: 900px) {
    .singleBrand {
        width: 700px;
    }

    .singleBrand h2 {
        width: 100%;
    }

}

@media screen and (max-width: 700px) {
    .singleBrand {
        width: 470px;
    }

    .singleBrand h2 {
        width: 100%;
    }

}

@media screen and (max-width: 470px) {
    .header_brand {
        width: 80%;
    }

    .singleBrand {
        // height: 800px;
    }

    .singleBrand h2 {
        width: 91%;
    }

}

// @media (max-width: 1000px) {
//     .singleBrand {
//         width: 75%;
//     }
// }


.divisor {
    border: 1px solid rgb(0, 15, 155);
    width: 100%;
}

.singleBrand {
    // max-height: 75vh;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 50px -10px #000;

    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(15px);
    // border-radius: 12px;
    // border: 1px solid rgba(255, 255, 255, 0.25);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    padding: 1rem;
    // overflow: hidden;
    gap: .5rem;

    h2 {
        // color: #fafafa;
        text-transform: capitalize;
        align-self: flex-start;
        display: flex;
        gap: .5rem;
        align-items: center;
        font-size: 2rem;
        // width: 100%;

        .config {
            transition: 0.3s ease-out;
            // margin-left: auto;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
                // filter: drop-shadow(0px 0px 1px #FFFFFF);
            }
        }

        .home {
            transition: 0.3s ease-out;
            margin: 0px;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
                // filter: drop-shadow(0px 0px 1px #FFFFFF);
            }
        }

    }

    &__section {
        &-conteiner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: 100%;
            position: relative;
            height: fit-content;

            max-height: 54vh;

            border-radius: 10px;
            padding: 1rem;
            gap: 1rem;
            white-space: nowrap;
            overflow: auto;
            align-items: flex-start;

        }

        &:last-child {
            justify-self: flex-start;
        }

        overflow: hidden;
        // overflow-y: auto;
        // cursor: pointer;
        margin: 0 0.5rem;
        // height: 160px;
        // width: 160px;
        // border: 1px solid rgba(255, 255, 255, 0.25);
        // padding: 1rem;
        display: inline-block;



        border-radius: 4px;
        // background: rgba(204, 204, 204, 0.8);
        box-shadow: 0 3px 10px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        transition: .2s ease-out;

        &:hover {
            transform: scale(1.013);
        }

        &-title {
            text-transform: uppercase;
            font-size: 1rem;
            text-align: center;
            white-space: normal;
        }

        a {
            background: #fafafa;
            border-radius: 8px;
            text-align: center;
        }

        &-list {
            &-item {
                cursor: pointer;
                text-transform: capitalize;
                text-align: left;
                font-size: .8rem;

                &:hover {
                    color: #fafafa
                }

            }
        }
    }

    .goBack {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding: 5px;
        width: 80px;
        border-radius: 8px;
        background: #fafafa;
        transition: .5s;
        cursor: pointer;
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);

        &:hover {
            transform: scale(1.1);
        }
    }

    .wrapper {
        // display: flex;
        // flex-direction: column;
        // gap: 0.5rem;
        height: 160px;
        width: 160px;
    }

    .addSection {
        z-index: 4;
        padding: 4px;
        width: fit-content;
        border-radius: 4px;
        background: #fafafa;
        transition: .2s;
        cursor: pointer;
        border: 1px solid grey;
        margin: 4px;

        &:hover {
            transform: scale(1.01);
        }

        &__first {
            margin-right: auto;
        }
    }
}

.botones_bottom_singlebrand {
    z-index: 4;
}

.logo_seccion_img {
    width: 160px;
    height: 160px;
    display: block;
}

.singleReport {
    // height: 90vh;
    // width: 100vh;
    height: 100%;
    width: 100%;
    display: inline-flex;
    position: relative;
    min-width: 5vw;

    &__goBack {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding: 5px;
        width: 80px;
        border-radius: 8px;
        background: #fafafa;
        transition: .5s;
        cursor: pointer;
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);

        &:hover {
            transform: scale(1.1);
        }
    }

    &__menu {
        position: absolute;
        bottom: 4rem;
        left: 1rem;
        padding: 5px;
        width: 80px;
        border-radius: 8px;
        background: #fafafa;
        transition: .5s;
        cursor: pointer;
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);

        &:hover {
            transform: scale(1.1);
        }
    }

    &__titles {
        background-color: #282c37;
        overflow-x: auto;
        height: 100%;
        width: 250px;
        display: flex;
        flex-direction: column;
        // gap: 2vh;
        // padding: 1vw;
        // border-right: solid 1px #fafafa;

        h2,
        h3 {
            text-transform: capitalize;
        }

        h2,
        h3 {
            // display: inline-block;

            font-size: 2.5vw;
            // width: 15vw;
        }

        h3 {
            font-size: 1rem;
        }

        a {
            font-size: 15.25px;
            letter-spacing: 1.5px;
        }

        // a {
        //     color: #fafafa;
        //     font-weight: 700;
        // }
    }

}

.wrapper_iframe {
    flex-grow: 1
}

.iframe_report {
    width: 100%;
    height: 100%;
}

.spinner_container {
    display: flex;
    align-items: center;
    height: 100%;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    display: inline-block;
    border-radius: 50%;
    border-left-color: #ea5a24;
    margin: auto;

    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.addNewSection {
    z-index: 10;
    position: absolute;
    height: 110vh;
    width: 100vw;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;

    &__span {
        margin-right: auto;
        font-size: large;
    }

    &__form {
        background: rgb(255 255 255 / 37%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 30%;
        height: fit-content;
        gap: 2rem;
        padding: 2rem;
        border-radius: 10px;
        border: 1px solid #fafafa50;
        // box-shadow: 0 6px 20px 0 rgba(255, 255, 255, 0.37);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);

        &-div {
            position: relative;
            height: 2rem;
            width: 100%;

            label {
                position: absolute;
                top: -1rem;
                font-size: .8rem;
                width: 50%;
                display: flex;
                align-items: center;
                padding-left: 5px;
                color: #000;
                z-index: 20;
                font-weight: 800;
                background: #fafafa;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                min-width: fit-content;

            }

            input {
                height: 2rem;
                border: #fafafa50 solid 2px;
                width: 100%;
                border-radius: 8px;
                color: #000000;
                padding: 5px;
                border-top-left-radius: 0px;
            }

        }

        p {
            text-align: center;
            padding: 5px;
            width: 80px;
            border-radius: 8px;
            background: #fafafa;
            transition: .5s;
            cursor: pointer;
            box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.brandConfig {
    height: fit-content;
    width: 50vw;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    position: relative;
    background-color: #ebebeb;

    h2,
    h3 {
        align-self: flex-start;
        text-transform: uppercase;
        color: black;
    }

    .goBack {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding: 5px;
        width: 80px;
        border-radius: 8px;
        background: #fafafa;
        transition: .5s;
        cursor: pointer;
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);

        &:hover {
            transform: scale(1.1);
        }
    }

    .addSection {

        padding: 5px;
        width: fit-content;
        border-radius: 8px;
        background: #fafafa;
        transition: .5s;
        cursor: pointer;
        box-shadow: 0px 2.87273px 14.0045px rgba(0, 0, 0, 1);

        &:hover {
            transform: scale(1.1);
        }
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        width: 75%;

        img {
            margin: .5rem 0;
        }

        input {
            color: #000;
            font-weight: 600;
        }

    }
}

// .flex-bottom-right {
//     justify-content: flex-end;
//     display: inline-block;
// }
.flex-bottom-right {
    margin-top: auto;
    margin-left: auto;
    padding-left: 3px;
    padding-right: 3px;

    // position: relative;
    // left: 57px;
    // top: 11px;
    display: inline-block;

}


.btn-addreporte-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    cursor: pointer;
}

.btn-addreporte-edit {
    color: #fff;
    background-color: #ffd900;
    border-color: #e7d808;
    cursor: pointer;
}

.btn-addreporte {
    font-weight: 400;
    border: 1px solid transparent;
    padding: 3px 8px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
}

.formas {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: -4;
}

.formasfondo_izq {
    position: absolute;
    top: 0px;
    z-index: -2;
}

.formasfondo_der {
    position: absolute;
    // right: 0px;
    right: 0vh;
    // bottom: 10vh;
    top: 0px;

    height: 100%;
    z-index: -2;
}

.forma_back_45 {
    /* Ellipse 45 */

    // position: absolute;
    width: 180.71px;
    height: 555.88px;
    // left: 1948.11px;
    // top: 940.44px;

    background: linear-gradient(180deg, #FAB51B 28.8%, rgba(196, 196, 196, 0) 128.8%);
    filter: blur(80px);
    transform: rotate(142.4deg);
    z-index: 1;

}

.forma_back_46 {
    /* Ellipse 46 */
    z-index: 1;
    // position: absolute;
    width: 150.9px;
    height: 240.42px;
    // left: 1675.39px;
    // top: 906.74px;

    background: linear-gradient(180deg, #E95A24 28.8%, rgba(196, 196, 196, 0) 128.8%);
    filter: blur(60px);
    transform: rotate(64.52deg);

}

.formas_3590 {
    /* Group 3590 */

    width: 718.42px;
    height: 728.68px;

}

.formas_254 {
    /* Ellipse 254 */

    width: 366.71px;
    height: 555.88px;

    background: linear-gradient(180deg, #FAB51B 28.8%, rgba(196, 196, 196, 0) 128.8%);
    filter: blur(140px);
    transform: rotate(-13.48deg);
}

.formas_25 {
    /* Ellipse 255 */
    position: absolute;
    top: 0px;
    width: 318.9px;
    height: 483.42px;

    background: linear-gradient(180deg, #E95A24 28.8%, rgba(196, 196, 196, 0) 128.8%);
    filter: blur(50px);
    transform: rotate(-91.36deg);

}

.usuario {
    &__root {
        width: 100%;
        height: 100%;
        background-color: #8e8e8e;
    }

    &__container {
        padding: 0px 10px;
        background-color: white;
        height: 100%;
        margin: 0px 5%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-between;
        overflow: scroll;
    }

    &__header {
        margin: 10px 0px;
    }

    &__box {}

    &__userview {
        width: 100%;
        margin: 0px;
    }

    &__form {}

    &__marca {
        width: 100%;

        &_title {
            font-size: small;
            margin: 0px;
            position: relative;
            top: 10px;
        }
    }

    &__email {
        width: 100%;

        &_title {
            font-size: small;
            margin: 0px;
            position: relative;
            top: 10px;
        }
    }

    &__pass {
        width: 100%;

        &_title {
            font-size: small;
            margin: 0px;
            position: relative;
            top: 12px;
        }

        &_data {
            &_input {
                &_text {
                    width: 50%;
                }

                &_submit {
                    position: relative;
                    right: 0px;
                    cursor: pointer;
                    border-radius: 1px;

                    &:hover {
                        background-color: rgb(193, 193, 193);
                    }

                    // margin-left: 10px;
                }

                &_div {
                    display: inline-block;
                    width: 50%;
                }
            }
        }
    }

    &__cerrar_sesión {
        margin: 10px 0px;

        p {
            width: fit-content;
            cursor: pointer;
            color: rgb(1, 1, 200);

            &:hover {
                color: rgb(0, 0, 96);
            }
        }
    }
}



.box_user {
    border: 1px solid #000;
    height: fit-content;
}

#box_permisos {
    width: 50%;
}

#box_pass {
    width: 25%;
}

#box_rol {
    width: 25%;
}

.buscar_usuario {
    background-color: burlywood;
}

.footer_brands_admin {
    position: absolute;
    width: 100%;
    background-color: #4cae4c;
    bottom: 5vh;
    margin: 0px;
    display: flex;

}

.box_footer_brands {
    margin-left: auto;
    display: inline-block;
    background-color: #FAB51B;
    // margin-right: 0px;
    // padding: 0px;
}

.beyond_header {
    position: fixed;
    top: 0px;
    height: 9vh;
    width: 100%;
    display: flex;
    background-color: #000;
    justify-content: center;
    // box-shadow: 0 4px 3px 2px rgb(0, 0, 0);

    // margin-bottom: auto;
}

.logo_beyond__header {
    margin-left: 2.5vh;
    margin-right: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.header_usuarios {
    color: white;
    display: flex;
    width: 15vw;
    margin-right: 5vw;
    justify-content: center;
    align-items: center;

}

.header_usuarios__child {
    height: 1.5rem;
    cursor: pointer;

    // padding: 2vh 0;
    // line-height: 0%;
    // margin-top: 25%;

}

.header_usuarios__child_sep {
    cursor: default;
    font-size: large;
    margin-left: 3px;
    margin-right: 3px;

}

.child_weight {
    font-weight: 900;
}

.header_usuario_salir {
    cursor: pointer;
}

.botones_seccion {
    position: absolute;
    bottom: 0.3rem;
    right: 0.3rem;
    margin-left: auto;
    margin-top: auto;
}

.cuadradito_edit {
    // display: none;
    opacity: 0.3;
    display: block;
    // margin: auto;
    // width: 50%;
    // line-height: 10;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

}

.cuadradito_edit_wrapper:hover>.cuadradito_edit {
    // display: block;
    opacity: 1;
    transition: opacity 0.4s;
    cursor: pointer;

}

.cuadradito_edit_wrapper {
    width: 25px;
    height: 9.5rem;
}

.ul_report {
    // margin-top: 20px;
    height: fit-content;
    max-height: 65vh;
    overflow: auto;
}

.botones_añadir_seccion {
    width: 100%;

}

.botones_añadir_seccion_botones {
    margin: 0 2%;
    float: right;
    cursor: pointer;
    padding: 2px 3px;
    border: 1px solid #c3c3c3;
    border-radius: 2px;
}

.botones_añadir_seccion_botones:disabled {
    cursor: default;
}

.botones_edit_secciones {
    // height: 100px;
    // height: fit-content;
    float: right;
    display: block;
    width: 15rem;
    height: 100%;
    position: absolute;
    top: -20%;
    right: 10px;
}

.img_foooter_brand {
    float: right;
    margin-left: auto;
}

.reportconfig {
    &__wrapper {
        overflow: auto;
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin: 0px 10%;
        gap: 3vh;
    }

    &__container {}

    &__seccion {
        border: 1px solid black;
        margin-bottom: 15px;
        padding: 1%;
    }

    &__reportesli {
        border-bottom: 1px solid grey;
        padding: 3px 0px;
    }

    &__lititle {
        display: inline-block;
    }

    &__libuttons {
        display: inline-block;
        float: right;
        width: 50px;
    }

    &__h3 {
        display: inline-block;
    }

    &__titulo_seccion_buttons {
        display: inline-block;
        float: right;
    }

    &__button {
        &_addreport {
            cursor: pointer;
            width: 50px;
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 3px;
            background-color: #5cb85c;
            border-color: #4cae4c;
            color: white;
        }

        &_addreport:hover {
            background-color: #449d44;
        }

        &_deletereport {
            cursor: pointer;
            width: 23px;
            // padding: 5px 10px;
            // font-size: 12px;
            // line-height: 1.5;
            border-radius: 3px;
            background-color: #d9534f;
            border-color: #d43f3a;
            color: white;
        }

        &_deletereport:hover {
            background-color: #c9302c;
        }

        &_editreport {
            cursor: pointer;
            width: 23px;
            margin-right: 4px;

            // padding: 5px 10px;
            // font-size: 12px;
            // line-height: 1.5;
            border-radius: 3px;
            background-color: #fae100;
            border-color: #fbff00;
            color: white;
        }

        &_editreport:hover {
            background-color: #e0a80b;

        }
    }

    &__svg-inline {
        width: 22.5px;
        height: 14px;
        // vertical-align: middle;
        position: relative;
        top: 2px;
    }

    &__modal {
        &_container {
            position: fixed;
            top: 0%;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);
        }

        &_wrapper {
            background-color: #fff5f5;

            margin: 15% auto;
            height: 50%;
            top: 50%;
            padding: 20px;
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        &_body {
            height: 80%;
        }

        // &_input {
        //     border: 1px solid grey;
        //     margin: auto;
        //     float: right;
        // }
        // &_select {
        //     border: 1px solid grey;

        //     margin: auto;
        //     float: right;

        // }
        &_div {
            // border-bottom: 1px solid black;
        }

        &_labelwrapper {
            display: inline-block;
            width: 8rem;
            border-bottom: 1px double rgb(145, 140, 140);

        }

        &_inputwrapper {
            display: inline-block;
            margin-left: 10%;

        }

        &_buttons {
            // display: inline-block;
            margin-left: auto;
            right: 10%;
            width: fit-content;

            &_buttons {
                margin: 0px 6px;
                padding: 3px 5px;
                cursor: pointer;
                border-radius: 4px;
            }

            &_agregar {
                background-color: #5cb85c;
                border-color: #4cae4c;
                color: white;

                &:hover {
                    background-color: #449d44;
                }

            }

            &_salir {
                background-color: #d9534f;
                border-color: #d43f3a;
                color: white;

                &:hover {
                    background-color: #c9302c;
                }

            }


        }
    }

}

.adminview {
    &__root {
        height: 102%;
        overflow: scroll;
        background-color: #8e8e8e;
    }

    &__container {
        margin: 0px 7%;
        background-color: white;
        padding: 10px 10px;
    }

    &__header {}

    &__permisos {
        width: 50%;

        &_header {
            display: inline-block;
            font-size: large;
            width: 100%;
            border-bottom: 1px solid black;
            font-weight: 550;

            &_all {
                width: 50%;
                display: inline-block;
            }
        }

        &_body {
            width: 100%;

            &_row {
                width: 100%;
                border-bottom: 1px solid black;

                &_marca {
                    display: inline-block;
                    width: 50%;
                }

                &_permisos {
                    display: inline-block;
                    width: 40%;
                }

                &_configseccion {
                    display: inline-block;
                    width: 10%;

                    &_svg {
                        width: 20px;
                        color: white;
                        background-color: orange;
                        padding: 3px;
                        border-radius: 2px;
                        vertical-align: middle;
                        cursor: pointer;

                        &:hover {
                            background-color: rgb(230, 150, 1);
                        }
                    }
                }
            }
        }
    }

    &__modal {
        &_container {
            position: fixed;
            top: 0%;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);
            overflow-y: scroll;
        }

        &_wrapper {
            background-color: #fff5f5;
            margin: 20px auto;
            min-height: 20%;
            height: fit-content;
            top: 50%;
            padding: 20px;
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        &_body {
            height: 80%;
            width: 65%;
        }

        &_labelwrapper {
            display: inline-block;
            width: 8rem;
            border-bottom: 1px double rgb(145, 140, 140);

        }

        &_inputwrapper {
            display: inline-block;
            margin-left: 10%;

        }

        &_row {
            border-bottom: 1px dotted black;

            &_nombre {
                display: inline-block;
                text-overflow: ellipsis;
                width: 300px;
                white-space: nowrap;
                overflow:hidden;
            }
            &_input {
                float: right;
            }
        }

        &_buttons {
            // display: inline-block;
            margin-left: auto;
            right: 10%;
            width: fit-content;

            &_buttons {
                margin: 0px 6px;
                padding: 3px 5px;
                cursor: pointer;
                border-radius: 4px;
            }

            &_agregar {
                background-color: #5cb85c;
                border-color: #4cae4c;
                color: white;

                &:hover {
                    background-color: #449d44;
                }
                &:disabled {
                    background-color: #6a6a6a;
                    cursor: default;
                }

            }

            &_salir {
                background-color: #d9534f;
                border-color: #d43f3a;
                color: white;

                &:hover {
                    background-color: #c9302c;
                }

            }


        }
    }

}

.listausuarios {
    &__root {
        display: flex;
        gap: 10%;
    }
    &__lista {
        width: 60%;
    }
    &__menuderecha {
        &_container {
            height: 100vh;
            position: fixed;
            right: 0%;
            width: 40%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    &__modal {
        &_container {
            position: fixed;
            top: 0%;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .6);
            overflow-y: scroll;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &_wrapper {
            border-radius: 24px;
            background: white;
            padding: 28px 29px 62px 127px;
            min-height: 20%;
            width: 100%;
            display: flex;
            flex-direction: column;
            max-width: 790px;
            align-items: center;
            .close-btn-container{
                width: 100%;
                text-align: end;
            }
            .modal-content{
                width: 100%;
                h3{
                    color: #E95A24;
                    font-family: Poppins;
                    font-size: 24px;
                    font-weight: 700;
                    padding-bottom: 41px;
                }
            }
            .save-btn-container{
                width: 100%;
            }
        }

        &_body {
            height: 80%;
            width: 100%;
        }

        &_labelwrapper {
            display: inline-block;
            width: 8rem;
            border-bottom: 1px double rgb(145, 140, 140);

        }

        &_inputwrapper {
            display: inline-block;
            margin-left: 10%;

        }

        &_row {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 90px;
            padding-bottom: 18px;
            &_nombre {
                display: inline-block;
                min-width: 40px;
            }
            &_input {
                display: inline;
                float: right;
                border-radius: 10px;
                border: 1px solid #FFB50B;
                background: #FFF;
                padding: 5px;
                min-width: 384px;
                &_select {
                    width: 50%;
                    
                }
                select {
                    width: 100%;
                    border-radius: 10px;
                background: #FFF;
                }
                input::placeholder{
                    color: black;
                }
            }
        }

        &_buttons {
            // display: inline-block;
            margin-left: auto;
            right: 10%;
            width: fit-content;

            &_buttons {
                cursor: pointer;
                border-radius: 4px;
            }

            &_agregar {
                border-radius: 15px;
                background: #656C73;
                padding: 10px 40px;
                color: white;
                transition: 0.5s;
                margin-top: 64px;

                &:hover {
                    background-color: #E95A24;
                }
                &:disabled {
                    background-color: #6a6a6a;
                    cursor: default;
                }

            }

            &_salir {
                background-color: transparent;
                border: 0;
                color: black;
                font-size: 24px;
                transition: 0.5s;
                margin: 0;
                padding: 0;

                &:hover {
                    color: red;
                }

            }


        }
    }

}



